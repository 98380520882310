import React from 'react';
import Greeter from '../views/Greeter';
import Diploma from '../views/Diploma';
import { PageLink, TabLink } from '../models';
import { jsOrange, midBlue, green, pink, markdownGrey, bgLightBlue } from '../theme/colors';

import staticData from './json/static.json';
import contactData from './json/contact.json';
import schoolData from './json/school.json';
import ccnaData from './json/ccna.json';
import pkg from '../../package.json';

import { calculateDiplomaProgress } from '../utils';

export const pages: PageLink[] = [
  {
    name: 'Home',
    url: '/',
    icon: 'home',
    isInternal: true,
    comp: () => (
      <Greeter
        staticData={staticData}
        contactData={contactData}
        repoUrl={pkg.repository.url}
      />
    ),
  },
  {
    name: 'Education',
    url: '/education',
    icon: 'graduation-cap',
    isInternal: true,
    badge: `${calculateDiplomaProgress(schoolData, 0)}%`,
    comp: () => <Diploma diplomaData={schoolData} />,
  },
  //{
  //  name: 'CCNA Training',
  //  url: '/ccna',
  //  icon: 'graduation-cap',
  //  isInternal: true,
  //  badge: `${calculateDiplomaProgress(ccnaData, 0)}%`,
  //  comp: () => <Diploma diplomaData={ccnaData} />,
  //},
];

export const tabs: TabLink[] = [
  {
    name: 'skills.js',
    url: '/skills',
    icon: ['fab', 'js'],
    color: jsOrange,
    mdFileName: 'skills',
  },
  {
    name: 'projects.config',
    url: '/projects',
    icon: 'sliders-h',
    color: green,
    mdFileName: 'projects',
  },
  {
    name: 'awards.html',
    url: '/awards',
    icon: ['fab', 'html5'],
    color: pink,
    mdFileName: 'awards',
  },
  {
    name: 'certificates.md',
    url: '/certificates',
    icon: ['fab', 'markdown'],
    color: markdownGrey,
    mdFileName: 'certificates',
  },
];
